import axios, { AxiosResponse } from "axios";
import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import {
  actAnalyticsFail01,
  actAnalyticsSucc01,
  actPublishContentsDailyFail,
  actPublishContentsDailySucc,
  actPublishContentsWeeklyFail,
  actPublishContentsWeeklySucc,
} from "../actions/actMWizard";
import { EStatus } from "../enum/status.enum";
import {
  IApiPublishContentsWeeklyData,
  IApiResultTYpe,
} from "../interfaces/iMarketWizard/iMWizard.interface";
import {
  IActMWizardAnalyticsReqeust01,
  IActMWizardPublishContentsDaily,
  IActMWizardPublishContentsWeekly,
  IActMWizardType,
} from "../interfaces/iMarketWizard/iMWizardAction.interface";
import { API_URL } from "../src/config/config.constant";

// weekly - 아이템 추천 리스트 request
function* requestPublishContentsWeekly(
  action: IActMWizardPublishContentsWeekly
) {
  // yield delay(1000)
  console.log("requestPublishContentsWeekly in");
  try {
    const token = action?.token;
    const headers = token ? { headers: { Authorization: "JWT " + token } } : {};
    const params = {};

    const apiUrl = API_URL + "/api/publish_contents/weekly/?page_size=100";
    const res: AxiosResponse<IApiResultTYpe> = yield call(
      axios.get,
      apiUrl,
      headers
    );
    console.log(res);
    console.log("requestPublishContentsWeekly out");
    if (res?.status === 200) {
      // 로그인 회원 정보
      const result = res?.data;
      if (result?.status === EStatus.SUCCESS) {
        yield put(
          actPublishContentsWeeklySucc(
            "weekly - 아이템 추천 리스트 성공",
            result
          )
        );
      } else {
        yield put(actPublishContentsWeeklyFail(result?.detail));
      }
    } else {
      yield put(
        actPublishContentsWeeklyFail("weekly - 아이템 추천 리스트 실패 1")
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      actPublishContentsWeeklyFail("weekly - 아이템 추천 리스트 실패 2")
    );
  }
}

// daily - 아이템 추천 리스트 request
function* requestPublishContentsDaily(action: IActMWizardPublishContentsDaily) {
  // yield delay(1000)
  try {
    console.log("requestPublishContentsDaily in");

    const token = action?.token;
    const headers = { headers: { Authorization: "JWT " + token } };
    const params = {};

    const apiUrl = API_URL + "/api/publish_contents/daily/?page_size=100";
    const res: AxiosResponse<IApiResultTYpe> = yield call(
      axios.get,
      apiUrl,
      headers
    );
    console.log(res);
    console.log("requestPublishContentsDaily out");
    if (res?.status === 200) {
      // 로그인 회원 정보
      const result = res?.data;
      if (result?.status === EStatus.SUCCESS) {
        yield put(
          actPublishContentsDailySucc("Daily - 아이템 추천 리스트 성공", result)
        );
      } else {
        yield put(actPublishContentsDailyFail(result?.detail));
      }
    } else {
      yield put(
        actPublishContentsDailyFail("Daily - 아이템 추천 리스트 실패 1")
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actPublishContentsDailyFail("Daily - 아이템 추천 리스트 실패 2"));
  }
}

// 분석_01 api 요청
function* requestAnalytics(action: IActMWizardAnalyticsReqeust01) {
  // yield delay(1000)
  try {
    console.log("action :" + action.cafe24Idx);
    // const token = action?.token;
    // const headers = token ? { headers: { Authorization: "JWT " + token } } : {};
    // const params = {};

    // const apiUrl = `${API_URL}/api/analysis/${action.analyticsId}/?content_type=${action.contentType}`;
    // const res: AxiosResponse<IApiResultTYpe> = yield call(
    //   axios.get,
    //   apiUrl,
    //   headers
    // );
    console.log("typeof :" + typeof action.cafe24Idx);
    const params = { cafe24Idx: action.cafe24Idx };
    const apiUrl = `https://swt-back.aimlabs.ai/mw/getAnalysis`;
    const res: AxiosResponse<IApiResultTYpe> = yield call(
      axios.post,
      apiUrl,
      params // POST 요청의 body로 params 전달
    );

    console.log(res);

    if (res?.status === 201) {
      // 로그인 회원 정보
      const result = res?.data;
      if (result) {
        console.log(result);
        yield put(actAnalyticsSucc01("분석01 성공", result));
      } else {
        // yield put(actAnalyticsFail01(result?.detail));
      }
    } else {
      const errorMsg = res?.data?.detail ?? "분석01 실패 1";
      yield put(actAnalyticsFail01(errorMsg));
    }
  } catch (error) {
    console.log(error);
    yield put(actAnalyticsFail01("분석01 실패 2"));
  }
}

function* watchMWizard() {
  yield takeEvery(
    IActMWizardType.REQUEST_PUBLISH_CONTENTS_WEEKLY,
    requestPublishContentsWeekly
  );
  yield takeEvery(
    IActMWizardType.REQUEST_PUBLISH_CONTENTS_DAILY,
    requestPublishContentsDaily
  );
  yield takeEvery(IActMWizardType.REQUEST_ANALYTICS_01, requestAnalytics);
}

function* sagaMWizard(): Generator {
  yield all([fork(watchMWizard)]);
}

export default sagaMWizard;

// const params = {};
// const apiUrl = `http://localhost:4000/mw/getAnalysis`;
// const res: AxiosResponse<IApiResultTYpe> = yield call(
//     axios.post,
//     apiUrl,
//     params  // POST 요청의 body로 params 전달
// );
