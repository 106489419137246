import axios, { AxiosResponse } from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { actGetReviewFail, actGetReviewSucc } from "../actions/actReview";
import { EStatus } from "../enum/status.enum";
import { ICommonApiResult } from "../interfaces/iCommon/iCommon.interface";
import { IReviewResult } from "../interfaces/iReview/iReview.interface";
import { IActGetReviewRequest, IActReviewType } from "../interfaces/iReview/iReviewAction.interface";
import { API_URL } from "../src/config/config.constant";


// [get] 의뢰 목록
function* requestGetReview(action: IActGetReviewRequest) {
    // yield delay(1000);
    try {
        const token = action?.token;
        const headers = { headers: { Authorization: 'JWT ' + token } };
        const params = {};

        const apiUrl = API_URL + `/api/review/?publish_content_id=${action?.publishContentId}&page_size=${action?.pageSize}&platform=${action?.platform}&item_key=${action?.itemKey}&grade=${action?.grade}`;
        const axioResult: AxiosResponse<ICommonApiResult> = yield call(
            axios.get,
            apiUrl,
            headers,
        );
        if (axioResult?.status === 200) {
            const result = axioResult?.data;
            // console.log('======')
            // console.log(result?.results)
            // console.log('======')
            const getReviewResult = result?.data?.results as IReviewResult[];

            if (result.status === EStatus.SUCCESS) {
                yield put(actGetReviewSucc('리뷰 조회 성공', getReviewResult));
            } else {
                yield put(actGetReviewFail(result?.detail as string));
            }
        } else {
            yield put(actGetReviewFail('[GET] /api/review/ 실패 1' as string));
        }
    } catch (error) {
        console.log(error);
        yield put(actGetReviewFail('[GET] /api/review/ 실패 2'));
    }
}

function* watchReview() {
    yield takeEvery(IActReviewType.GET_REVIEW_REQUEST, requestGetReview);
}

function* sagaReview(): Generator {
    yield all([fork(watchReview)]);
}
  
export default sagaReview;
