import axios, { AxiosResponse } from "axios";
import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import { actUserInfoFail, actUserInfoSucc, actUserLoginFail, actUserLoginSucc } from "../actions/actUser";
import { IUserApiLoginResult, IUserData, IUserInfoData } from "../interfaces/iUser/iUser.interface";
import { IActUserInfo, IActUserRequestLogin, IActUserType } from "../interfaces/iUser/iUserAction.interface";
import { API_URL } from "../src/config/config.constant";
import { JWT_COOKIE_KEY } from "../src/constant/key.constant";
import { setCookie } from "../src/lib/cookie";

// 로그인
function* requestUserLogin(action: IActUserRequestLogin) {
    // yield delay(1000);
    try {
        const headers = { headers: { Authorization: 'bearer ' } };
        const accessToken = action.kakaotalkAccessToken;
        // const formData = new FormData();
        // formData.append('id', id);
        const params = { access_token: accessToken };

        const apiUrl = API_URL + '/account/kakao/';
        const result: AxiosResponse<IUserApiLoginResult> = yield call(
            axios.post,
            apiUrl,
            params,
            headers,
        );
        if (result?.status === 200) {
            // jwt 저장
            const token: string = result?.data?.token ?? '';
            const cookieResult = setCookie(JWT_COOKIE_KEY, token, 60*60*24*365);
            if (cookieResult !== true) {
                yield put(actUserLoginFail('토큰 저장 실패'));
                return;
            }

            // 로그인 회원 정보
            const user = result?.data?.user;
            const userData = {
                id: user?.id,
                email: user?.email,
                name: user?.name,
            } as IUserData;
            yield put(actUserLoginSucc('로그인 성공', userData));
        } else {
            yield put(actUserLoginFail('로그인 실패 1'));
        }
    } catch (error) {
        console.log(error);
        yield put(actUserLoginFail('로그인 실패 2'));
    }
}


// 회원 정보
function* requestUserInfo(action: IActUserInfo) {
    // yield delay(1000);
    try {
        const token = action?.token;
        const headers = { headers: { Authorization: 'JWT ' + token } };
        const params = {};

        const apiUrl = API_URL + '/api/profile/info/';
        const result: AxiosResponse<IUserInfoData> = yield call(
            axios.post,
            apiUrl,
            params,
            headers,
        );
        if (result?.status === 200) {
            // 로그인 회원 정보
            const userInfo = result?.data;
            yield put(actUserInfoSucc('회원 정보 불러오기 성공', userInfo));
        } else {
            yield put(actUserInfoFail('회원 정보 불러오기 실패 1'));
        }
    } catch (error) {
        console.log(error);
        yield put(actUserInfoFail('회원 정보 불러오기 실패 2'));
    }
}

  
function* watchUser() {
    yield takeEvery(IActUserType.REQUEST_USER_LOGIN, requestUserLogin);
    yield takeEvery(IActUserType.REQUEST_USER_INFO, requestUserInfo);
}

function* sagaUser(): Generator {
    yield all([fork(watchUser)]);
}
  
export default sagaUser;
