import { HYDRATE } from "next-redux-wrapper";
import { IMWizardStateInterface } from "../interfaces/iMarketWizard/iMWizard.interface";
import {
  IActMWizard,
  IActMWizardType,
} from "../interfaces/iMarketWizard/iMWizardAction.interface";

export const initialMWizardState: IMWizardStateInterface = {
  loadingPublishContentsWeekly: false, // weekly - 아이템 추천 리스트 request
  publishContentsWeeklySuccMsg: "", // weekly - 아이템 추천 리스트 성공 메시지
  publishContentsWeeklyFailMsg: "", // weekly - 아이템 추천 리스트 실패 메시지
  publishContentsWeeklyData: null, // weekly - 분석 아이템 리스트 결과

  loadingPublishContentsDaily: false, // Daily - 아이템 추천 리스트 request
  publishContentsDailySuccMsg: "", // Daily - 아이템 추천 리스트 성공 메시지
  publishContentsDailyFailMsg: "", // Daily - 아이템 추천 리스트 실패 메시지
  publishContentsDailyData: null, // Daily - 분석 아이템 리스트 결과

  loadingAnalyticsWeekly01: false, // 분석_01 api 요청
  analyticsSuccMsg01: "", // 분석_01 api 요청 성공 메시지
  analyticsFailMsg01: "", // 분석_01 api 요청 실패 메시지
  analyticsData01: null, // 분석_01 api 결과

  loadingAnalyticsWeekly02: false, // 분석_02 api 요청
  analyticsSuccMsg02: "", // 분석_02 api 요청 성공 메시지
  analyticsFailMsg02: "", // 분석_02 api 요청 실패 메시지
  analyticsData02: null, // 분석_02 api 결과

  loadingAnalyticsWeekly03: false, // 분석_03 api 요청
  analyticsSuccMsg03: "", // 분석_03 api 요청 성공 메시지
  analyticsFailMsg03: "", // 분석_03 api 요청 실패 메시지
  analyticsData03: null, // 분석_03 api 결과
};

interface HydratePayload {
  rdcMWizard: IMWizardStateInterface;
}

const rdcMWizard = (
  state = initialMWizardState,
  // action: AnyAction,
  action: IActMWizard
): IMWizardStateInterface => {
  switch (action.type) {
    case IActMWizardType.REQUEST_PUBLISH_CONTENTS_WEEKLY:
      return {
        ...state,
        ...{
          token: action.token,
          loadingPublishContentsWeekly: true,
          publishContentsWeeklySuccMsg: "",
          publishContentsWeeklyFailMsg: "",
          publishContentsWeeklyData: null,
        },
      };
    case IActMWizardType.PUBLISH_CONTENTS_WEEKLY_SUCC:
      return {
        ...state,
        ...{
          loadingPublishContentsWeekly: false,
          publishContentsWeeklySuccMsg: action.publishContentsWeeklySuccMsg,
          publishContentsWeeklyFailMsg: "",
          publishContentsWeeklyData: action.publishContentsWeeklyData,
        },
      };
    case IActMWizardType.PUBLISH_CONTENTS_WEEKLY_FAIL:
      return {
        ...state,
        ...{
          loadingPublishContentsWeekly: false,
          publishContentsWeeklySuccMsg: "",
          publishContentsWeeklyFailMsg: action.publishContentsWeeklyFailMsg,
          publishContentsWeeklyData: null,
        },
      };

    case IActMWizardType.REQUEST_PUBLISH_CONTENTS_DAILY:
      return {
        ...state,
        ...{
          token: action.token,
          loadingPublishContentsDaily: true,
          publishContentsDailySuccMsg: "",
          publishContentsDailyFailMsg: "",
          publishContentsDailyData: null,
        },
      };
    case IActMWizardType.PUBLISH_CONTENTS_DAILY_SUCC:
      return {
        ...state,
        ...{
          loadingPublishContentsDaily: false,
          publishContentsDailySuccMsg: action.publishContentsDailySuccMsg,
          publishContentsDailyFailMsg: "",
          publishContentsDailyData: action.publishContentsDailyData,
        },
      };
    case IActMWizardType.PUBLISH_CONTENTS_DAILY_FAIL:
      return {
        ...state,
        ...{
          loadingPublishContentsDaily: false,
          publishContentsDailySuccMsg: "",
          publishContentsDailyFailMsg: action.publishContentsDailyFailMsg,
          publishContentsDailyData: null,
        },
      };

    case IActMWizardType.INIT_ANALYTICS_01:
      return {
        ...state,
        ...{
          loadingAnalyticsWeekly01: false,
          analyticsSuccMsg01: "",
          analyticsFailMsg01: "",
          analyticsData01: null,
        },
      };
    case IActMWizardType.INIT_MSG_ANALYTICS_01:
      return {
        ...state,
        ...{
          analyticsSuccMsg01: "",
          analyticsFailMsg01: "",
        },
      };
    case IActMWizardType.REQUEST_ANALYTICS_01:
      return {
        ...state,
        ...{
          token: action.token,
          loadingAnalyticsWeekly01: true,
          analyticsSuccMsg01: "",
          analyticsFailMsg01: "",
          // analyticsData01: null
        },
      };
    case IActMWizardType.ANALYTICS_SUCC_01:
      return {
        ...state,
        ...{
          loadingAnalyticsWeekly01: false,
          analyticsSuccMsg01: action.analyticsSuccMsg01,
          analyticsFailMsg01: "",
          analyticsData01: action.analyticsData01,
        },
      };
    case IActMWizardType.ANALYTICS_FAIL_01:
      return {
        ...state,
        ...{
          loadingAnalyticsWeekly01: false,
          analyticsSuccMsg01: "",
          analyticsFailMsg01: action.analyticsFailMsg01,
          analyticsData01: null,
        },
      };
    default:
      return state;
  }
};

export default rdcMWizard;
