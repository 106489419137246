import { IImportCallback, IPaymentCuidData, IPaymentMonthlyData, ICouponData, ICouponResult, } from "../interfaces/iPayment/iPayment.interface";
import { IActCouponSucc, IActPaymentCuidFail, IActPaymentCuidInit, IActPaymentCuidRequest, IActPaymentCuidSucc, IActPaymentMonthlyFail, IActPaymentMonthlyInit, IActPaymentMonthlyRequest, IActPaymentMonthlySucc, IActPaymentType,IActCouponFail } from "../interfaces/iPayment/iPaymentAction.interface";


// {{url}}/api/payment/cuid 초기화
export function actPaymentCuidInit(): IActPaymentCuidInit {
    return {
        type: IActPaymentType.CUID_INIT,
    };
}
// {{url}}/api/payment/cuid 호출
export function actPaymentCuidRequest(token: string): IActPaymentCuidRequest {
    return {
        type: IActPaymentType.CUID_REQUEST,
        token,
    };
}
// {{url}}/api/payment/cuid 성공
export function actPaymentCuidSucc(paymentCuidSuccMsg: string, paymentCuidData: IPaymentCuidData): IActPaymentCuidSucc {
    return {
        type: IActPaymentType.CUID_SUCC_MSG,
        paymentCuidSuccMsg,
        paymentCuidData
    };
}
// {{url}}/api/payment/cuid 실패
export function actPaymentCuidFail(paymentCuidFailMsg: string): IActPaymentCuidFail {
    return {
        type: IActPaymentType.CUID_FAIL_MSG,
        paymentCuidFailMsg
    };
}


// {{url}}/api/payment/monthly 초기화
export function actPaymentMonthlyInit(): IActPaymentMonthlyInit {
    return {
        type: IActPaymentType.PAYMENT_MONTHLY_INIT,
    };
}
// {{url}}/api/payment/monthly 호출
export function actPaymentMonthlyRequest(token: string, body: IImportCallback): IActPaymentMonthlyRequest {
    return {
        type: IActPaymentType.PAYMENT_MONTHLY_REQUEST,
        token,
        body
    };
}
// {{url}}/api/payment/monthly 성공
export function actPaymentMonthlySucc(paymentMonthlySuccMsg: string, paymentMonthlyData: IPaymentMonthlyData): IActPaymentMonthlySucc {
    return {
        type: IActPaymentType.PAYMENT_MONTHLY_SUCC_MSG,
        paymentMonthlySuccMsg,
        paymentMonthlyData
    };
}
// {{url}}/api/payment/monthly 실패
export function actPaymentMonthlyFail(paymentMonthlyFailMsg: string): IActPaymentMonthlyFail {
    return {
        type: IActPaymentType.PAYMENT_MONTHLY_FAIL_MSG,
        paymentMonthlyFailMsg
    };
}

// {{url}}/api/coupon/use 호출
export function actCouponRequest(token: string, body: any): any {
    return {
        type: IActPaymentType.COUPON_REQUEST,
        token,
        body
    }
} 

// {{url}}/api/coupon/use  성공
export function actCouponSucc(couponSuccMsg: string, couponData: ICouponResult): IActCouponSucc {
    return {
        type: IActPaymentType.COUPON_SUCC_MSG,
        couponSuccMsg,
        couponData
    };
}
// {{url}}/api/coupon/use  실패
export function actCouponFail(couponFailMsg: string): IActCouponFail {
    return {
        type: IActPaymentType.COUPON_FAIL_MSG,
        couponFailMsg
    };
}