import { IUserInfoData } from "./iUser.interface";

export enum IActUserType {
    REQUEST_USER_LOGIN = 'REQUEST_USER_LOGIN', // 로그인 api 요청
    USER_LOGIN_SUCC = 'USER_LOGIN_SUCC', // 로그인 성공
    USER_LOGIN_FAIL = 'USER_LOGIN_FAIL', // 로그인 실패
    USER_LOGOUT = 'USER_LOGOUT', // 로그아웃
    REQUEST_USER_INFO = 'REQUEST_USER_INFO', // token으로 유저정보 가져오기
    REQUEST_USER_INFO_SUCC = 'REQUEST_USER_INFO_SUCC', // token으로 유저정보 가져오기 성공
    REQUEST_USER_INFO_FAIL = 'REQUEST_USER_INFO_FAIL' // token으로 유저정보 가져오기 실패
}

export type IActUser = IActUserRequestLogin
                    | IActUserLoginSucc
                    | IActUserLoginFail
                    | IActUserLogout
                    | IActUserInfo
                    | IActUserInfoSucc
                    | IActUserInfoFail;

// 로그인 요청
export interface IActUserRequestLogin {
    type: IActUserType.REQUEST_USER_LOGIN;
    kakaotalkAccessToken: string;
}

// 로그인 성공
export interface IActUserLoginSucc {
    type: IActUserType.USER_LOGIN_SUCC,
    loginSuccMsg: string,
    loginUserData?: IUserInfoData
}

// 로그인 실패
export interface IActUserLoginFail {
    type: IActUserType.USER_LOGIN_FAIL,
    loginFailMsg: string
}

// 로그아웃
export interface IActUserLogout {
    type: IActUserType.USER_LOGOUT
}

// token으로 유저 정보 가져오기
export interface IActUserInfo {
    type: IActUserType.REQUEST_USER_INFO,
    token: string
}
// token으로 유저 정보 가져오기 성공
export interface IActUserInfoSucc {
    type: IActUserType.REQUEST_USER_INFO_SUCC,
    loginUserData?: IUserInfoData,
    userInfoSuccMsg: string
}
// token으로 유저 정보 가져오기 실패
export interface IActUserInfoFail {
    type: IActUserType.REQUEST_USER_INFO_FAIL,
    userInfoFailMsg: string
}
