import axios, { AxiosResponse } from 'axios';
import { all, call, delay, fork, put, takeEvery } from 'redux-saga/effects';
import {
  actGetOrderFail,
  actGetOrderSucc,
  actOrderConfirmFail,
  actOrderConfirmSucc,
  actOrderFail,
  actOrderSucc,
} from '../actions/actOrder';
import { EStatus } from '../enum/status.enum';
import { IGetOrderData } from '../interfaces/iOrder/iOrder.interface';
import {
  IActOrderConfirmRequest,
  IActOrderRequest,
  IActOrderType,
} from '../interfaces/iOrder/iOrderAction.interface';
import { IApiPaymentResult } from '../interfaces/iPayment/iPayment.interface';
import { IActPaymentCuidRequest } from '../interfaces/iPayment/iPaymentAction.interface';
import { API_URL } from '../src/config/config.constant';

// [get] 의뢰 목록
function* requestGetOrder(action: IActPaymentCuidRequest) {
  // yield delay(1000);

  try {
    const token = action?.token;
    const headers = { headers: { Authorization: 'JWT ' + token } };
    const params = {};

    const apiUrl = API_URL + '/api/order/?page_size=100';
    // const axioResult: AxiosResponse<IApiPaymentResult> = yield call(
    const axioResult: AxiosResponse<any> = yield call(
      axios.get,
      apiUrl,
      headers
    );
    if (axioResult?.status === 200) {
      const result = axioResult?.data;
      // console.log('======')
      // console.log(result?.results)
      // console.log('======')
      const getOrderResult = result?.data?.results as IGetOrderData[];

      if (result.status === EStatus.SUCCESS) {
        yield put(
          actGetOrderSucc('시장조사의뢰 신청 내역 조회 성공', getOrderResult)
        );
      } else {
        yield put(actGetOrderFail(result?.detail as string));
      }
    } else {
      // yield put(actGetOrderFail('[GET] /api/order/ 실패 1' as string));
    }
  } catch (error) {
    console.log(error);
    // yield put(actGetOrderFail('[GET] /api/order/ 실패 2'));
  }
}

// [POST] 시장조사 의뢰
function* requestOrder(action: IActOrderRequest) {
  // yield delay(1000);
  try {
    const token = action?.token;
    const headers = { headers: { Authorization: 'JWT ' + token } };
    const params = { url: action?.smartStoreLink };

    let regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (regex.test(action?.smartStoreLink) == false) {
      throw new Error('잘못된 url 입니다.');
    }

    const apiUrl = API_URL + '/api/order/';
    const axioResult: AxiosResponse<IApiPaymentResult> = yield call(
      axios.post,
      apiUrl,
      params,
      headers
    );
    if (axioResult?.status === 200) {
      const result = axioResult?.data;
      // console.log('======');
      // console.log(result);
      // console.log('======');

      if (result.status === EStatus.SUCCESS) {
        if (result?.data.keywords.length == 0) {
          yield put(actOrderSucc('다른 링크로 시도해주세요!', result?.data));
        } else {
          yield put(actOrderSucc('시장 키워드 도출 성공!', result?.data));
        }
      } else {
        yield put(actOrderFail(result?.detail as string));
      }
    } else {
      // yield put(actOrderFail(`[POST] /api/order/ 실패 1` as string));
    }
  } catch (error:any) {
    const detail = error?.response?.data?.detail ?? error;
    yield put(actOrderFail(`${detail}`));
  }
}

// [POST] 시장조사 의뢰 완료 요청
function* requestOrderConfirm(action: IActOrderConfirmRequest) {
  // yield delay(1000);
  try {
    const token = action?.token;
    const headers = { headers: { Authorization: 'JWT ' + token } };
    const params = { keyword_id: action?.keywordId, url: action?.url };

    const apiUrl = API_URL + '/api/order/confirm/';
    const axioResult: AxiosResponse<IApiPaymentResult> = yield call(
      axios.post,
      apiUrl,
      params,
      headers
    );
    if (axioResult?.status === 200) {
      const result = axioResult?.data;
      console.log('======');
      console.log(result);
      console.log('======');

      if (result?.status === EStatus.SUCCESS) {
        // const getOrderResult = result?.data as IGetOrderData[];
        yield put(actOrderConfirmSucc(result?.detail as string));
      } else {
        yield put(actOrderConfirmFail(result?.detail as string));
      }
    } else {
      // yield put(actOrderConfirmFail('[POST] /api/order/confirm/ 실패 1'));
    }
  } catch (error) {
    console.log(error);
    // yield put(actOrderConfirmFail('[POST] /api/order/confirm/ 실패 2'));
  }
}

function* watchOrder() {
  yield takeEvery(IActOrderType.GET_ORDER_REQUEST, requestGetOrder);
  yield takeEvery(IActOrderType.ORDER_REQUEST, requestOrder);
  yield takeEvery(IActOrderType.ORDER_CONFIRM_REQUEST, requestOrderConfirm);
}

function* sagaOrder(): Generator {
  yield all([fork(watchOrder)]);
}

export default sagaOrder;
