import { ReviewPosition } from '../enum/analytics/review-position.enum';
import { IReviewResult } from '../interfaces/iReview/iReview.interface';
import { IActGetReviewFail, IActGetReviewInit, IActGetReviewMsgInit, IActGetReviewRequest, IActGetReviewSucc, IActReviewType } from '../interfaces/iReview/iReviewAction.interface';

// [GET] {{url}}/api/review/ 초기화
export function actGetReviewInit(): IActGetReviewInit {
    return {
        type: IActReviewType.GET_REVIEW_INIT
    };
}
// [GET] {{url}}/api/review/ 메시지 초기화
export function actGetReviewMsgInit(): IActGetReviewMsgInit {
    return {
        type: IActReviewType.GET_REVIEW_MSG_INIT
    };
}
// [GET] {{url}}/api/review/ 호출
export function actGetReviewRequest(token: string, publishContentId: number, platform: string, itemKey: number, pageSize: number, grade: number, reviewPosition?: ReviewPosition): IActGetReviewRequest {
    return {
        type: IActReviewType.GET_REVIEW_REQUEST,
        token,
        publishContentId,
        platform,
        itemKey,
        pageSize,
        grade,
        reviewPosition
    };
}
// [GET] {{url}}/api/review/ 성공
export function actGetReviewSucc(getReviewSuccMsg: string, getReviewData: IReviewResult[], reviewPosition?: ReviewPosition): IActGetReviewSucc {
    return {
        type: IActReviewType.GET_REVIEW_SUCC_MSG,
        getReviewSuccMsg,
        getReviewData,
        reviewPosition
    };
}
// [GET] {{url}}/api/review/ 실패
export function actGetReviewFail(getReviewFailMsg: string): IActGetReviewFail {
    return {
        type: IActReviewType.GET_REVIEW_FAIL_MSG,
        getReviewFailMsg
    };
}
