import { HYDRATE } from 'next-redux-wrapper';
import { IPaymentStateInterface } from '../interfaces/iPayment/iPayment.interface';
import { IActPayment, IActPaymentType } from '../interfaces/iPayment/iPaymentAction.interface';
import { JWT_COOKIE_KEY } from '../src/constant/key.constant';

export const initialPaymentState: IPaymentStateInterface = {
    loadingPymentCuid: false,
    pymentCuidSuccMsg: '',
    pymentCuidFailMsg: '',
    pymentCuidData: null,

    loadingPymentMonthly: false, // {{url}}/api/payment/monthly/ request
    pymentMonthlySuccMsg: '', // {{url}}/api/payment/monthly/ 성공 메시지
    pymentMonthlyFailMsg: '', // {{url}}/api/payment/monthly/ 실패 메시지
    paymentMonthlyData: null, // {{url}}/api/payment/monthly/ 결과

    loadingCoupon: false,
    couponSuccMsg: '',
    couponFailMsg: '',
    couponData: null
};

interface HydratePayload {
    rdcUser: IPaymentStateInterface;
}

const rdcPayment = (
    state = initialPaymentState,
    // action: AnyAction,
    action: IActPayment | { type: typeof HYDRATE; payload: HydratePayload },
): IPaymentStateInterface => {
    switch (action.type) {
        case HYDRATE:
            return { ...state, ...action.payload.rdcUser };
        case IActPaymentType.CUID_INIT:
            return {
                ...state,
                ...{
                    loadingPymentCuid: false,
                    pymentCuidSuccMsg: '',
                    pymentCuidFailMsg: '',
                    pymentCuidData: null,
                },
            };
        case IActPaymentType.CUID_REQUEST:
            return {
                ...state,
                ...{
                    loadingPymentCuid: true,
                    pymentCuidSuccMsg: '',
                    pymentCuidFailMsg: '',
                    pymentCuidData: null,
                },
            };
        case IActPaymentType.CUID_SUCC_MSG:
            return {
                ...state,
                ...{
                    loadingPymentCuid: false,
                    pymentCuidSuccMsg: action?.paymentCuidSuccMsg,
                    pymentCuidFailMsg: '',
                    pymentCuidData: action?.paymentCuidData,
                },
            };
        case IActPaymentType.CUID_FAIL_MSG:

            return {
                ...state,
                ...{
                    loadingPymentCuid: false,
                    pymentCuidSuccMsg: '',
                    pymentCuidFailMsg: action?.paymentCuidFailMsg,
                    pymentCuidData: null,
                },
            };

        case IActPaymentType.PAYMENT_MONTHLY_INIT:
            return {
                ...state,
                ...{
                    loadingPymentMonthly: false,
                    pymentMonthlySuccMsg: '',
                    pymentMonthlyFailMsg: '',
                    paymentMonthlyData: null,
                },
            };
        case IActPaymentType.PAYMENT_MONTHLY_REQUEST:
            return {
                ...state,
                ...{
                    loadingPymentMonthly: true,
                    pymentMonthlySuccMsg: '',
                    pymentMonthlyFailMsg: '',
                    paymentMonthlyData: null,
                },
            };
        case IActPaymentType.PAYMENT_MONTHLY_SUCC_MSG:
            return {
                ...state,
                ...{
                    loadingPymentMonthly: false,
                    pymentMonthlySuccMsg: action?.paymentMonthlySuccMsg,
                    pymentMonthlyFailMsg: '',
                    paymentMonthlyData: action?.paymentMonthlyData,
                },
            };
        case IActPaymentType.PAYMENT_MONTHLY_FAIL_MSG:
            return {
                ...state,
                ...{
                    loadingPymentMonthly: false,
                    pymentMonthlySuccMsg: '',
                    pymentMonthlyFailMsg: action?.paymentMonthlyFailMsg,
                    paymentMonthlyData: null,
                },
            };
        case IActPaymentType.COUPON_REQUEST:
            return {
                ...state,
                ...{
                    loadingcoupon: true,
                    couponSuccMsg: '',
                    couponFailMsg: '',
                    couponData: null,
                },
            };
        case IActPaymentType.COUPON_SUCC_MSG:
            return {
                ...state,
                ...{
                    loadingcoupon: false,
                    couponSuccMsg: action?.couponSuccMsg,
                    couponFailMsg: '',
                    couponData: action?.couponData,
                },
            };
        case IActPaymentType.COUPON_FAIL_MSG:

            return {
                ...state,
                ...{
                    loadingcoupon: false,
                    couponSuccMsg: '',
                    couponFailMsg: action?.couponFailMsg,
                    couponData: null,
                },
            };
        default:
            return state;
    }
};

export default rdcPayment;
