import { HYDRATE } from 'next-redux-wrapper';
import { ReviewPosition } from '../enum/analytics/review-position.enum';
import { IReviewStateInterface } from '../interfaces/iReview/iReview.interface';
import { IActReview, IActReviewType } from '../interfaces/iReview/iReviewAction.interface';

export const initialReviewState: IReviewStateInterface = {

    loadingGetReview: false, // [GET] {{url}}/api/review/ request
    getReviewSuccMsg: '', // [GET] {{url}}/api/review/ 성공 메시지
    getReviewFailMsg: '', // [GET] {{url}}/api/review/ 실패 메시지

    getReviewData: null, // [GET] {{url}}/api/review/ 결과
    getReviewDataStar5Top1: null, // [GET] {{url}}/api/review/ 결과 - 5점 top1 리뷰
    getReviewDataStar5Top2: null, // [GET] {{url}}/api/review/ 결과 - 5점 top2 리뷰
    getReviewDataStar5Top3: null, // [GET] {{url}}/api/review/ 결과 - 5점 top3 리뷰
    getReviewDataStar1Top1: null, // [GET] {{url}}/api/review/ 결과 - 1점 top1 리뷰
    getReviewDataStar1Top2: null, // [GET] {{url}}/api/review/ 결과 - 1점 top2 리뷰
    getReviewDataStar1Top3: null, // [GET] {{url}}/api/review/ 결과 - 1점 top3 리뷰
};

interface HydratePayload {
    rdcMWizard: IReviewStateInterface;
}

const rdcReview = (
    state = initialReviewState,
    // action: AnyAction,
    action: IActReview,
): IReviewStateInterface => {
    switch (action.type) {
        case IActReviewType.GET_REVIEW_INIT:
            return {
                ...state,
                ...{
                    loadingGetReview: false,
                    getReviewSuccMsg: '',
                    getReviewFailMsg: '',
                    getReviewData: null,
                }
            };
        case IActReviewType.GET_REVIEW_MSG_INIT:
            return {
                ...state,
                ...{
                    getReviewSuccMsg: '',
                    getReviewFailMsg: '',
                }
            };
        case IActReviewType.GET_REVIEW_REQUEST:
            return {
                ...state,
                ...{
                    loadingGetReview: true,
                    getReviewSuccMsg: '',
                    getReviewFailMsg: '',
                    getReviewData: null,
                }
            };
        case IActReviewType.GET_REVIEW_SUCC_MSG:
            return {
                ...state,
                ...{
                    loadingGetReview: true,
                    getReviewSuccMsg: action.getReviewSuccMsg,
                    getReviewFailMsg: '',
                    getReviewData: action.getReviewData,

                    getReviewDataStar5Top1: action.reviewPosition === ReviewPosition.STAR5TOP1 ? action.getReviewData : null,
                    getReviewDataStar5Top2: action.reviewPosition === ReviewPosition.STAR5TOP2 ? action.getReviewData : null,
                    getReviewDataStar5Top3: action.reviewPosition === ReviewPosition.STAR5TOP3 ? action.getReviewData : null,
                    getReviewDataStar1Top1: action.reviewPosition === ReviewPosition.STAR1TOP1 ? action.getReviewData : null,
                    getReviewDataStar1Top2: action.reviewPosition === ReviewPosition.STAR1TOP2 ? action.getReviewData : null,
                    getReviewDataStar1Top3: action.reviewPosition === ReviewPosition.STAR1TOP3 ? action.getReviewData : null,
                }
            };
        case IActReviewType.GET_REVIEW_FAIL_MSG:
            return {
                ...state,
                ...{
                    loadingGetReview: true,
                    getReviewSuccMsg: '',
                    getReviewFailMsg: action.getReviewFailMsg,
                    getReviewData: null,
                }
            };
        default:
            return state;
    }
};

export default rdcReview;
