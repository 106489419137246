import { all, fork } from 'redux-saga/effects';
import sagaMWizard from './sagaMWizard';
import sagaUser from './sagaUser';
import sagaPayment from './sagaPayment';
import sagaOrder from './sagaOrder';
import sagaReview from './sagaReview';
import sagaSurvey from './sagaSurvey';

// import sagaExample from './sagaExample';
// import sagaApiExample from './sagaApiExample';

export default function* rootSaga() {
    yield all([
        fork(sagaUser),
        fork(sagaMWizard),
        fork(sagaPayment),
        fork(sagaOrder),
        fork(sagaReview),
        fork(sagaSurvey)
    ]);
}
