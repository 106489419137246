import { ReviewPosition } from "../../enum/analytics/review-position.enum";
import { IReviewResult } from "./iReview.interface";

export enum IActReviewType {
    GET_REVIEW_INIT = 'GET_REVIEW_INIT', // 리뷰 리스트 API 초기화
    GET_REVIEW_MSG_INIT = 'GET_REVIEW_MSG_INIT', // 시리뷰 리스트 API 메시지 초기화
    GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST', // 리뷰 리스트 API 요청
    GET_REVIEW_SUCC_MSG = 'GET_REVIEW_SUCC_MSG', // 리뷰 리스트 API 성공
    GET_REVIEW_FAIL_MSG = 'GET_REVIEW_FAIL_MSG', // 리뷰 리스트 API 실패
}



export type IActReview = IActGetReviewInit
                        | IActGetReviewMsgInit
                        | IActGetReviewRequest
                        | IActGetReviewSucc
                        | IActGetReviewFail;

// [GET] {{url}}/api/review/ 초기화
export interface IActGetReviewInit {
    type: IActReviewType.GET_REVIEW_INIT;
}
// [GET] {{url}}/api/review/ 메시지 초기화
export interface IActGetReviewMsgInit {
    type: IActReviewType.GET_REVIEW_MSG_INIT;
}
// [GET] {{url}}/api/review/ request
export interface IActGetReviewRequest {
    type: IActReviewType.GET_REVIEW_REQUEST;
    token: string;
    publishContentId: number;
    platform: string;
    itemKey: number;
    pageSize: number;
    grade: number;
    reviewPosition?: ReviewPosition;
}
// [GET] {{url}}/api/review/ 성공
export interface IActGetReviewSucc {
    type: IActReviewType.GET_REVIEW_SUCC_MSG;
    getReviewSuccMsg: string;
    getReviewData: IReviewResult[];
    reviewPosition?: ReviewPosition;
}
// [GET] {{url}}/api/review/ 실패
export interface IActGetReviewFail {
    type: IActReviewType.GET_REVIEW_FAIL_MSG;
    getReviewFailMsg: string;
}
