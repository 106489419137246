import { IGerMySurveyResult } from "../interfaces/iSurvey/iSurvey.interface";
import { IActGetMySurveyFail, IActGetMySurveyInit, IActGetMySurveyMsgInit, IActGetMySurveyRequest, IActGetMySurveySucc, IActSaveMySurveyFail, IActSaveMySurveyMsgInit, IActSaveMySurveyRequest, IActSaveMySurveySucc, IActSurveyType } from "../interfaces/iSurvey/iSurveyAction.interface";

// [GET] {{url}}/api/survey 초기화
export function actGetMySurveyInit(): IActGetMySurveyInit {
    return {
        type: IActSurveyType.GET_MY_SURVEY_INIT
    };
}
// [GET] {{url}}/api/survey msg 초기화
export function actGetMySurveyMsgInit(): IActGetMySurveyMsgInit {
    return {
        type: IActSurveyType.GET_MY_SURVEY_MSG_INIT
    };
}
// [GET] {{url}}/api/survey request
export function actGetMySurveyRequest(token: string): IActGetMySurveyRequest {
    return {
        type: IActSurveyType.GET_MY_SURVEY_REQUEST,
        token
    };
}
// [GET] {{url}}/api/survey succ
export function actGetMySurveySucc(getMySurveySuccMsg: string, getMySurveyData: IGerMySurveyResult): IActGetMySurveySucc {
    return {
        type: IActSurveyType.GET_MY_SURVEY_SUCC_MSG,
        getMySurveySuccMsg,
        getMySurveyData
    };
}
// [GET] {{url}}/api/survey succ
export function actGetMySurveyFail(getMySurveyFailMsg: string): IActGetMySurveyFail {
    return {
        type: IActSurveyType.GET_MY_SURVEY_FAIL_MSG,
        getMySurveyFailMsg
    };
}


// [POST] {{url}}/api/survey 메시지 초기화
export function actSaveMySurveyMsgInit(): IActSaveMySurveyMsgInit {
    return {
        type: IActSurveyType.SAVE_MY_SURVEY_MSG_INIT,
    };
}
// [POST] {{url}}/api/survey request
export function actSaveMySurveyRequest(token: string, body: any): IActSaveMySurveyRequest {
    return {
        type: IActSurveyType.SAVE_MY_SURVEY_REQUEST,
        token,
        body
    };
}
// [POST] {{url}}/api/survey succ
export function actSaveMySurveySucc(saveMySurveySuccMsg: string): IActSaveMySurveySucc {
    return {
        type: IActSurveyType.SAVE_MY_SURVEY_SUCC_MSG,
        saveMySurveySuccMsg
    };
}
// [POST] {{url}}/api/survey succ
export function actSaveMySurveyFail(saveMySurveyFailMsg: string): IActSaveMySurveyFail {
    return {
        type: IActSurveyType.SAVE_MY_SURVEY_FAIL_MSG,
        saveMySurveyFailMsg
    };
}
