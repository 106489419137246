import { IImportCallback, IPaymentCuidData, IPaymentMonthlyData, ICouponData ,ICouponResult} from "./iPayment.interface";

export enum IActPaymentType {

    CUID_INIT = 'CUID_INIT', // customer_uid 불러오기 API 초기화
    CUID_REQUEST = 'CUID_REQUEST', // customer_uid 불러오기 API 요청
    CUID_SUCC_MSG = 'CUID_SUCC_MSG', // customer_uid 불러오기 API 성공
    CUID_FAIL_MSG = 'CUID_FAIL_MSG', // customer_uid 불러오기 API 실패

    PAYMENT_MONTHLY_INIT = 'PAYMENT_MONTHLY_INIT', // 정기결제 요청 API 초기화
    PAYMENT_MONTHLY_REQUEST = 'PAYMENT_MONTHLY_REQUEST', // 정기결제 요청 API 요청
    PAYMENT_MONTHLY_SUCC_MSG = 'PAYMENT_MONTHLY_SUCC_MSG', // 정기결제 요청 API 성공
    PAYMENT_MONTHLY_FAIL_MSG = 'PAYMENT_MONTHLY_FAIL_MSG', // 정기결제 요청 API 실패

    COUPON_REQUEST = 'COUPON_REQUEST',
    COUPON_SUCC_MSG = 'COUPON_SUCC_MSG',
    COUPON_FAIL_MSG = 'COUPON_FAIL_MSG'
}

export type IActPayment = IActPaymentCuidInit
                        | IActPaymentCuidRequest
                        | IActPaymentCuidSucc
                        | IActPaymentCuidFail
                        | IActPaymentMonthlyInit
                        | IActPaymentMonthlyRequest
                        | IActPaymentMonthlySucc
                        | IActPaymentMonthlyFail
                        | IActCouponRequest
                        | IActCouponSucc
                        | IActCouponFail;

// {{url}}/api/payment/cuid/ 초기화
export interface IActPaymentCuidInit {
    type: IActPaymentType.CUID_INIT;
}
// {{url}}/api/payment/cuid/ 호출
export interface IActPaymentCuidRequest {
    type: IActPaymentType.CUID_REQUEST;
    token: string;
}
// {{url}}/api/payment/cuid/ 성공
export interface IActPaymentCuidSucc {
    type: IActPaymentType.CUID_SUCC_MSG;
    paymentCuidSuccMsg: string;
    paymentCuidData: IPaymentCuidData;
}
// {{url}}/api/payment/cuid/ 실패
export interface IActPaymentCuidFail {
    type: IActPaymentType.CUID_FAIL_MSG;
    paymentCuidFailMsg: string;
}

/** @description {{url}}/api/payment/monthly/ 초기화 */
export interface IActPaymentMonthlyInit {
    type: IActPaymentType.PAYMENT_MONTHLY_INIT;
}
/** @description {{url}}/api/payment/monthly/ 호출 */
export interface IActPaymentMonthlyRequest {
    type: IActPaymentType.PAYMENT_MONTHLY_REQUEST;
    token: string;
    body: IImportCallback;
}
/** @description {{url}}/api/payment/monthly/ 성공 */
export interface IActPaymentMonthlySucc {
    type: IActPaymentType.PAYMENT_MONTHLY_SUCC_MSG;
    paymentMonthlySuccMsg: string;
    paymentMonthlyData: IPaymentMonthlyData;
}
/** @description {{url}}/api/payment/monthly/ 실패 */
export interface IActPaymentMonthlyFail {
    type: IActPaymentType.PAYMENT_MONTHLY_FAIL_MSG;
    paymentMonthlyFailMsg: string;
}

export interface IActCouponRequest{
    type: IActPaymentType.COUPON_REQUEST;
    token: string;
    body: ICouponData;
}
export interface IActCouponSucc{
    type: IActPaymentType.COUPON_SUCC_MSG;
    couponSuccMsg: string;
    couponData: ICouponResult
}
export interface IActCouponFail{
    type: IActPaymentType.COUPON_FAIL_MSG;
    couponFailMsg: string;
    
}