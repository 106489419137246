import { combineReducers, Reducer, AnyAction } from 'redux';
import { IRootStateInterface } from '../interfaces/iRootState';
// import rdcExample from './rdcExample';
// import rdcApiExample from './rdcApiExample';
import rdcDefault from './rdcDefault';
import rdcUser from './rdcUser';
import rdcMWizard from './rdcMWizard';
import rdcPayment from './rdcPayment';
import rdcOrder from './rdcOrder';
import rdcReview from './rdcReview';
import rdcSurvey from './rdcSurvey';

const rootReducer: Reducer<IRootStateInterface, AnyAction> = combineReducers<IRootStateInterface>({
    // rdcExample,
    // rdcApiExample,
    rdcDefault,
    rdcUser,
    rdcMWizard,
    rdcPayment,
    rdcOrder,
    rdcReview,
    rdcSurvey
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
