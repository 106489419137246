import '../styles/globals.css';

import React from 'react';
import { AppProps } from 'next/app';
import { NextPage } from 'next';
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { wrapper } from '../store';
import Head from 'next/head';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../src/theme';
import * as ga from '../src/lib/ga'

const MyApp: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => {

    const router = useRouter()

    useEffect(() => {
      const handleRouteChange = (url: any) => {
        ga.pageview(url)
      }
      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange)
  
      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }, [router.events])
  
    return (
        <React.Fragment>
        <Head>
            <title>Market Wizards</title>

            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            <meta name="viewport" content="width=device-width, user-scalable=no"/>
        </Head>
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Component {...pageProps} />
        </ThemeProvider>
        </React.Fragment>
    );
}

export default wrapper.withRedux(MyApp);

