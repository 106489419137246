import { HYDRATE } from 'next-redux-wrapper';
import { ISurveyStateInterface } from '../interfaces/iSurvey/iSurvey.interface';
import { IActSurvey, IActSurveyType } from '../interfaces/iSurvey/iSurveyAction.interface';

export const initialSurveyState: ISurveyStateInterface = {

    loadingGetMySurvey: false, // [GET] {{url}}/api/survey request
    getMySurveySuccMsg: '', // [GET] {{url}}/api/survey 성공 메시지
    getMySurveyFailMsg: '', // [GET] {{url}}/api/survey 실패 메시지
    getMySurveyData: null, // [GET] {{url}}/api/survey 결과

    loadingSaveMySurvey: false, // [POST] {{url}}/api/survey 저장 request
    saveMySurveySuccMsg: '', // [POST] {{url}}/api/survey 저장 성공 메시지
    saveMySurveyFailMsg: '', // [POST] {{url}}/api/survey 저장 실패 메시지
    saveMySurveyData: null, // [POST] {{url}}/api/survey 저장 결과
};

interface HydratePayload {
    rdcSurvey: ISurveyStateInterface;
}

const rdcSurvey = (
    state = initialSurveyState,
    // action: AnyAction,
    action: IActSurvey,
): ISurveyStateInterface => {
    switch (action.type) {
        case IActSurveyType.GET_MY_SURVEY_INIT:
            return {
                ...state,
                ...{
                    loadingGetMySurvey: false,
                    getMySurveySuccMsg: '',
                    getMySurveyFailMsg: '',
                    getMySurveyData: null,
                }
            };
        case IActSurveyType.GET_MY_SURVEY_MSG_INIT:
            return {
                ...state,
                ...{
                    getMySurveySuccMsg: '',
                    getMySurveyFailMsg: '',
                }
            };
        case IActSurveyType.GET_MY_SURVEY_REQUEST:
            return {
                ...state,
                ...{
                    loadingGetMySurvey: true,
                    getMySurveySuccMsg: '',
                    getMySurveyFailMsg: '',
                    getMySurveyData: null,
                }
            };
        case IActSurveyType.GET_MY_SURVEY_SUCC_MSG:
            return {
                ...state,
                ...{
                    loadingGetMySurvey: false,
                    getMySurveySuccMsg: action.getMySurveySuccMsg,
                    getMySurveyFailMsg: '',
                    getMySurveyData: action.getMySurveyData,
                }
            };
        case IActSurveyType.GET_MY_SURVEY_FAIL_MSG:
            return {
                ...state,
                ...{
                    loadingGetMySurvey: false,
                    getMySurveySuccMsg: '',
                    getMySurveyFailMsg: action.getMySurveyFailMsg,
                    getMySurveyData: null,
                }
            };

        
        case IActSurveyType.SAVE_MY_SURVEY_REQUEST:
            return {
                ...state,
                ...{
                    loadingSaveMySurvey: true,
                    saveMySurveySuccMsg: '',
                    saveMySurveyFailMsg: '',
                    saveMySurveyData: null,
                }
            };
        case IActSurveyType.SAVE_MY_SURVEY_SUCC_MSG:
            return {
                ...state,
                ...{
                    loadingSaveMySurvey: false,
                    saveMySurveySuccMsg: action.saveMySurveySuccMsg,
                    saveMySurveyFailMsg: '',
                    saveMySurveyData: null,
                }
            };
        case IActSurveyType.SAVE_MY_SURVEY_FAIL_MSG:
            return {
                ...state,
                ...{
                    loadingSaveMySurvey: false,
                    saveMySurveySuccMsg: '',
                    saveMySurveyFailMsg: action.saveMySurveyFailMsg,
                    saveMySurveyData: null,
                }
            };
        default:
            return state;
    }
};

export default rdcSurvey;
