import { IGetOrderData, IOrderData } from '../interfaces/iOrder/iOrder.interface';
import { IActGetOrderFail, IActGetOrderInit, IActGetOrderMsgInit, IActGetOrderRequest, IActGetOrderSucc, IActOrderConfirmFail, IActOrderConfirmInit, IActOrderConfirmRequest, IActOrderConfirmSucc, IActOrderFail, IActOrderInit, IActOrderMsgInit, IActOrderRequest, IActOrderSucc, IActOrderType } from '../interfaces/iOrder/iOrderAction.interface';

// [GET] {{url}}/api/order/ 초기화
export function actGetOrderInit(): IActGetOrderInit {
    return {
        type: IActOrderType.GET_ORDER_INIT
    };
}
// [GET] {{url}}/api/order/ msg 초기화
export function actGetOrderMsgInit(): IActGetOrderMsgInit {
    return {
        type: IActOrderType.GET_ORDER_MSG_INIT
    };
}
// [GET] {{url}}/api/order/ 호출
export function actGetOrderRequest(token: string): IActGetOrderRequest {
    return {
        type: IActOrderType.GET_ORDER_REQUEST,
        token,
    };
}
// [GET] {{url}}/api/order/ 성공
export function actGetOrderSucc(getOrderSuccMsg: string, getOrderData: IGetOrderData[]): IActGetOrderSucc {
    return {
        type: IActOrderType.GET_ORDER_SUCC_MSG,
        getOrderSuccMsg,
        getOrderData
    };
}
// [GET] {{url}}/api/order/ 실패
export function actGetOrderFail(getOrderFailMsg: string): IActGetOrderFail {
    return {
        type: IActOrderType.GET_ORDER_FAIL_MSG,
        getOrderFailMsg
    };
}


// [POST] {{url}}/api/order/ 초기화
export function actOrderInit(): IActOrderInit {
    return {
        type: IActOrderType.ORDER_INIT
    };
}
// [POST] {{url}}/api/order/ 초기화
export function actOrderMsgInit(): IActOrderMsgInit {
    return {
        type: IActOrderType.ORDER_MSG_INIT
    };
}
// [POST] {{url}}/api/order/ 호출
export function actOrderRequest(token: string, smartStoreLink: string): IActOrderRequest {
    return {
        type: IActOrderType.ORDER_REQUEST,
        token,
        smartStoreLink
    };
}
// [POST] {{url}}/api/order/ 성공
export function actOrderSucc(orderSuccMsg: string, orderData: IOrderData): IActOrderSucc {
    return {
        type: IActOrderType.ORDER_SUCC_MSG,
        orderSuccMsg,
        orderData
    };
}
// [POST] {{url}}/api/order/ 실패
export function actOrderFail(orderFailMsg: string): IActOrderFail {
    return {
        type: IActOrderType.ORDER_FAIL_MSG,
        orderFailMsg
    };
}


// [POST] {{url}}/api/order/confirm/ 초기화
export function actOrderConfirmInit(): IActOrderConfirmInit {
    return {
        type: IActOrderType.ORDER_CONFIRM_INIT
    };
}
// [POST] {{url}}/api/order/confirm/ 호출
export function actOrderConfirmRequest(token: string, keywordId: number, url: string): IActOrderConfirmRequest {
    return {
        type: IActOrderType.ORDER_CONFIRM_REQUEST,
        token,
        keywordId,
        url
    };
}
// [POST] {{url}}/api/order/confirm/ 성공
export function actOrderConfirmSucc(orderConfirmSuccMsg: string): IActOrderConfirmSucc {
    return {
        type: IActOrderType.ORDER_CONFIRM_SUCC_MSG,
        orderConfirmSuccMsg
    };
}
// [POST] {{url}}/api/order/confirm/ 실패
export function actOrderConfirmFail(orderConfirmFailMsg: string): IActOrderConfirmFail {
    return {
        type: IActOrderType.ORDER_CONFIRM_FAIL_MSG,
        orderConfirmFailMsg
    };
}