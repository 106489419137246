import { HYDRATE } from 'next-redux-wrapper';
import { IMWizardStateInterface } from '../interfaces/iMarketWizard/iMWizard.interface';
import { IActMWizard, IActMWizardType } from '../interfaces/iMarketWizard/iMWizardAction.interface';
import { IOrderStateInterface } from '../interfaces/iOrder/iOrder.interface';
import { IActOrder, IActOrderType } from '../interfaces/iOrder/iOrderAction.interface';

export const initialOrderState: IOrderStateInterface = {

    loadingGetOrder: false, // [GET] {{url}}/api/order/ request
    getOrderSuccMsg: '', // [GET] {{url}}/api/order/ 성공 메시지
    getOrderFailMsg: '', // [GET] {{url}}/api/order/ 실패 메시지
    getOrderData: null, // [GET] {{url}}/api/order/ 결과

    loadingOrder: false, // [POST] {{url}}/api/order/ request
    orderSuccMsg: '', // [POST] {{url}}/api/order/ 성공 메시지
    orderFailMsg: '', // [POST] {{url}}/api/order/ 실패 메시지
    orderData: null, // [POST] {{url}}/api/order/ 결과

    loadingOrderConfirm: false, // [POST] {{url}}/api/order/Confirm/ request
    orderConfirmSuccMsg: '', // [POST] {{url}}/api/order/Confirm/ 성공 메시지
    orderConfirmFailMsg: '', // [POST] {{url}}/api/order/Confirm/ 실패 메시지
    orderConfirmData: null, // [POST] {{url}}/api/order/Confirm/ 결과    
};

interface HydratePayload {
    rdcMWizard: IOrderStateInterface;
}

const rdcOrder = (
    state = initialOrderState,
    // action: AnyAction,
    action: IActOrder,
): IOrderStateInterface => {
    switch (action.type) {
        case IActOrderType.GET_ORDER_INIT:
            return {
                ...state,
                ...{
                    loadingGetOrder: false,
                    getOrderSuccMsg: '',
                    getOrderFailMsg: '',
                    getOrderData: null,
                }
            };
        case IActOrderType.GET_ORDER_MSG_INIT:
            return {
                ...state,
                ...{
                    getOrderSuccMsg: '',
                    getOrderFailMsg: ''
                }
            };
        case IActOrderType.GET_ORDER_REQUEST:
            return {
                ...state,
                ...{
                    loadingGetOrder: true,
                    getOrderSuccMsg: '',
                    getOrderFailMsg: '',
                    getOrderData: null,
                }
            };
        case IActOrderType.GET_ORDER_SUCC_MSG:
            return {
                ...state,
                ...{
                    loadingGetOrder: false,
                    getOrderSuccMsg: action?.getOrderSuccMsg,
                    getOrderFailMsg: '',
                    getOrderData: action?.getOrderData,
                }
            };
        case IActOrderType.GET_ORDER_FAIL_MSG:
            return {
                ...state,
                ...{
                    loadingGetOrder: false,
                    getOrderSuccMsg: '',
                    getOrderFailMsg: action?.getOrderFailMsg,
                    getOrderData: null,
                }
            };


        case IActOrderType.ORDER_INIT:
            return {
                ...state,
                ...{
                    loadingOrder: false,
                    orderSuccMsg: '',
                    orderFailMsg: '',
                    orderData: null,
                }
            };
        case IActOrderType.ORDER_MSG_INIT:
            return {
                ...state,
                ...{
                    orderSuccMsg: '',
                    orderFailMsg: '',
                }
            };
        case IActOrderType.ORDER_REQUEST:
            return {
                ...state,
                ...{
                    loadingOrder: true,
                    orderSuccMsg: '',
                    orderFailMsg: '',
                    orderData: null,
                }
            };
        case IActOrderType.ORDER_SUCC_MSG:
            return {
                ...state,
                ...{
                    loadingOrder: false,
                    orderSuccMsg: action?.orderSuccMsg,
                    orderFailMsg: '',
                    orderData: action?.orderData,
                }
            };
        case IActOrderType.ORDER_FAIL_MSG:
            return {
                ...state,
                ...{
                    loadingOrder: false,
                    orderSuccMsg: '',
                    orderFailMsg: action?.orderFailMsg,
                    orderData: null,
                }
            };


            case IActOrderType.ORDER_CONFIRM_INIT:
                return {
                    ...state,
                    ...{
                        loadingOrderConfirm: false,
                        orderConfirmSuccMsg: '',
                        orderConfirmFailMsg: '',
                        orderConfirmData: null,
                    }
                };
            case IActOrderType.ORDER_CONFIRM_REQUEST:
                return {
                    ...state,
                    ...{
                        loadingOrderConfirm: true,
                        orderConfirmSuccMsg: '',
                        orderConfirmFailMsg: '',
                        orderConfirmData: null,
                    }
                };
            case IActOrderType.ORDER_CONFIRM_SUCC_MSG:
                return {
                    ...state,
                    ...{
                        loadingOrderConfirm: false,
                        orderConfirmSuccMsg: action?.orderConfirmSuccMsg,
                        orderConfirmFailMsg: '',
                        orderConfirmData: null,
                    }
                };
            case IActOrderType.ORDER_CONFIRM_FAIL_MSG:
                return {
                    ...state,
                    ...{
                        loadingOrderConfirm: false,
                        orderConfirmSuccMsg: '',
                        orderConfirmFailMsg: action?.orderConfirmFailMsg,
                        orderConfirmData: null,
                    }
                };
        default:
            return state;
    }
};

export default rdcOrder;
