import { IUserInfoData, IUserData } from '../interfaces/iUser/iUser.interface';
import { IActUserType, IActUserLoginFail, IActUserLoginSucc, IActUserRequestLogin, IActUserLogout, IActUserInfo, IActUserInfoSucc, IActUserInfoFail } from '../interfaces/iUser/iUserAction.interface';

// 로그인
export function actRequestLogin(kakaotalkAccessToken: string): IActUserRequestLogin {
    return {
        type: IActUserType.REQUEST_USER_LOGIN,
        kakaotalkAccessToken,
    };
}

// 로그인 성공
export function actUserLoginSucc(loginSuccMsg: string, loginUserData: IUserData): IActUserLoginSucc {
    return {
        type: IActUserType.USER_LOGIN_SUCC,
        loginSuccMsg,
        loginUserData: {
            user: loginUserData
        }
    }
}

// 로그인 실패
export function actUserLoginFail(loginFailMsg: string): IActUserLoginFail {
    return {
        type: IActUserType.USER_LOGIN_FAIL,
        loginFailMsg
    }
}

// 로그아웃
export function actUserLogout(): IActUserLogout {
    return {
        type: IActUserType.USER_LOGOUT
    }
}

// token으로 유저정보 가저오기
export function actRequestUserInfo(token: string): IActUserInfo {
    return {
        type: IActUserType.REQUEST_USER_INFO,
        token
    }
}
// token으로 유저정보 가저오기 성공
export function actUserInfoSucc(userInfoSuccMsg: string, loginUserData: IUserInfoData): IActUserInfoSucc {
    return {
        type: IActUserType.REQUEST_USER_INFO_SUCC,
        loginUserData,
        userInfoSuccMsg
    }
}
// token으로 유저정보 가저오기 실패
export function actUserInfoFail(userInfoFailMsg: string): IActUserInfoFail {
    return {
        type: IActUserType.REQUEST_USER_INFO_FAIL,
        userInfoFailMsg
    }
}
