import { IGetOrderData, IOrderData } from "./iOrder.interface";

export enum IActOrderType {
    GET_ORDER_INIT = 'GET_ORDER_INIT', // 시장조사 의뢰 내역 API 초기화
    GET_ORDER_MSG_INIT = 'GET_ORDER_MSG_INIT', // 시장조사 의뢰 내역 API 메시지 초기화
    GET_ORDER_REQUEST = 'GET_ORDER_REQUEST', // 시장조사 의뢰 내역 API 요청
    GET_ORDER_SUCC_MSG = 'GET_ORDER_SUCC_MSG', // 시장조사 의뢰 내역 API 성공
    GET_ORDER_FAIL_MSG = 'GET_ORDER_FAIL_MSG', // 시장조사 의뢰 내역 API 실패

    ORDER_INIT = 'ORDER_INIT', // 시장조사 의뢰 신청 API 초기화
    ORDER_MSG_INIT = 'ORDER_MSG_INIT', // 시장조사 의뢰 신청 API MSG 초기화
    ORDER_REQUEST = 'ORDER_REQUEST', // 시장조사 의뢰 신청 API 요청
    ORDER_SUCC_MSG = 'ORDER_SUCC_MSG', // 시장조사 의뢰 신청 API 성공
    ORDER_FAIL_MSG = 'ORDER_FAIL_MSG', // 시장조사 의뢰 신청 API 실패

    ORDER_CONFIRM_INIT = 'ORDER_CONFIRM_INIT', // /api/order/confirm/ 의뢰 완료 요청 API 초기화
    ORDER_CONFIRM_REQUEST = 'ORDER_CONFIRM_REQUEST', // /api/order/confirm/ 의뢰 완료 요청 API 요청
    ORDER_CONFIRM_SUCC_MSG = 'ORDER_CONFIRM_SUCC_MSG', // /api/order/confirm/ 의뢰 완료 요청 API 성공
    ORDER_CONFIRM_FAIL_MSG = 'ORDER_CONFIRM_FAIL_MSG', // /api/order/confirm/ 의뢰 완료 요청 API 실패
}

export type IActOrder = IActGetOrderInit
                        | IActGetOrderMsgInit
                        | IActGetOrderRequest
                        | IActGetOrderSucc
                        | IActGetOrderFail
                        | IActOrderInit
                        | IActOrderMsgInit
                        | IActOrderRequest
                        | IActOrderSucc
                        | IActOrderFail
                        | IActOrderConfirmInit
                        | IActOrderConfirmRequest
                        | IActOrderConfirmSucc
                        | IActOrderConfirmFail;

// [GET] {{url}}/api/order/ 초기화
export interface IActGetOrderInit {
    type: IActOrderType.GET_ORDER_INIT;
}
// [GET] {{url}}/api/order/ MSG 초기화
export interface IActGetOrderMsgInit {
    type: IActOrderType.GET_ORDER_MSG_INIT;
}
// [GET] {{url}}/api/order/ 호출
export interface IActGetOrderRequest {
    type: IActOrderType.GET_ORDER_REQUEST;
    token: string;
}
// [GET] {{url}}/api/order/ 성공
export interface IActGetOrderSucc {
    type: IActOrderType.GET_ORDER_SUCC_MSG;
    getOrderSuccMsg: string;
    getOrderData: IGetOrderData[];
}
// [GET] {{url}}/api/order/ 실패
export interface IActGetOrderFail {
    type: IActOrderType.GET_ORDER_FAIL_MSG;
    getOrderFailMsg: string;
}

// [POST] {{url}}/api/order/ 초기화
export interface IActOrderInit {
    type: IActOrderType.ORDER_INIT;
}
// [POST] {{url}}/api/order/ msg 초기화
export interface IActOrderMsgInit {
    type: IActOrderType.ORDER_MSG_INIT;
}
// [POST] {{url}}/api/order/ 호출
export interface IActOrderRequest {
    type: IActOrderType.ORDER_REQUEST;
    token: string;
    smartStoreLink: string;
}
// [POST] {{url}}/api/order/ 성공
export interface IActOrderSucc {
    type: IActOrderType.ORDER_SUCC_MSG;
    orderSuccMsg: string;
    orderData: IOrderData;
}
// [POST] {{url}}/api/order/ 실패
export interface IActOrderFail {
    type: IActOrderType.ORDER_FAIL_MSG;
    orderFailMsg: string;
}

// [POST] {{url}}/api/order/confirm/ 초기화
export interface IActOrderConfirmInit {
    type: IActOrderType.ORDER_CONFIRM_INIT;
}
// [POST] {{url}}/api/order/confirm/ 호출
export interface IActOrderConfirmRequest {
    type: IActOrderType.ORDER_CONFIRM_REQUEST;
    token: string;
    keywordId: number;
    url: string;
}
// [POST] {{url}}/api/order/confirm/ 성공
export interface IActOrderConfirmSucc {
    type: IActOrderType.ORDER_CONFIRM_SUCC_MSG;
    orderConfirmSuccMsg: string;
}
// [POST] {{url}}/api/order/confirm/ 실패
export interface IActOrderConfirmFail {
    type: IActOrderType.ORDER_CONFIRM_FAIL_MSG;
    orderConfirmFailMsg: string;
}
