import axios, { AxiosResponse } from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { actGetMySurveyFail, actGetMySurveySucc, actSaveMySurveyFail, actSaveMySurveySucc } from "../actions/actSurvey";
import { EStatus } from "../enum/status.enum";
import { ICommonApiResult } from "../interfaces/iCommon/iCommon.interface";
import { IGerMySurveyResult } from "../interfaces/iSurvey/iSurvey.interface";
import { IActGetMySurveyRequest, IActSaveMySurveyRequest, IActSurveyType } from "../interfaces/iSurvey/iSurveyAction.interface";
import { API_URL } from "../src/config/config.constant";
import { GUEST_SURVEY_ANSWER_DATA_KEY, GUEST_SURVEY_RESULT_DATA_KEY } from "../src/constant/key.constant";
import { getCookie, setCookie } from "../src/lib/cookie";


// [get] 나의 서베이 정보
function* requestGetMySurvey(action: IActGetMySurveyRequest) {
    // yield delay(1000);
    try {
        const token = action?.token;
        const headers = token ? { headers: { Authorization: 'JWT ' + token } } : {};
        const params = {};

        const apiUrl = API_URL + `/api/survey/`;
        const axioResult: AxiosResponse<ICommonApiResult> = yield call(
            axios.get,
            apiUrl,
            headers,
        );
        if (axioResult?.status === 200) {
            const result = axioResult?.data;
            // console.log('======')
            // console.log(result?.data)
            // console.log('======')
            const getSurveyResult = result?.data as IGerMySurveyResult;

            if (!token) {
                // 비회원일 경우
                try {
                    const guest_survey_result_data = getCookie(GUEST_SURVEY_RESULT_DATA_KEY);
                    if (guest_survey_result_data) {
                        // 비회원이고 서베이 결과가 있으면
                        getSurveyResult.result = JSON.parse(guest_survey_result_data).result;
                    }
                } catch (e) {
                    console.log(e);
                }
            }

            if (result.status === EStatus.SUCCESS) {
                yield put(actGetMySurveySucc('나의 서베이 불러오기 성공', getSurveyResult));
            } else {
                yield put(actGetMySurveyFail(result?.detail as string));
            }
        } else {
            yield put(actGetMySurveyFail('[GET] /api/survey 실패 1' as string));
        }
    } catch (error) {
        console.log(error);
        yield put(actGetMySurveyFail('[GET] /api/survey 실패 2'));
    }
}

// [post] 서베이 저장
function* requestSaveMySurvey(action: IActSaveMySurveyRequest) {
    // yield delay(1000);
    try {
        const token = action?.token;
        const headers = token ? { headers: { Authorization: 'JWT ' + token } } : {};
        const params = action.body;

        if (!token) {
            // 비회원이면
            // 쿠키에 답변 저장
            try {
                setCookie(GUEST_SURVEY_ANSWER_DATA_KEY, JSON.stringify(params), 60*60*24*3);
            } catch (e) {
                console.log(e);
            }
        }

        const apiUrl = API_URL + `/api/survey/`;
        const axioResult: AxiosResponse<ICommonApiResult> = yield call(
            axios.post,
            apiUrl,
            params,
            headers,
        );
        if (axioResult?.status === 200) {
            const result = axioResult?.data;
            // console.log('======')
            // console.log(result?.data)
            // console.log('======')

            if (!token) {
                // 비회원이면
                // 쿠키에 결과 저장
                try {
                    setCookie(GUEST_SURVEY_RESULT_DATA_KEY, JSON.stringify(result?.data), 60*60*24*3);
                } catch (e) {
                    console.log(e);
                }
            }

            if (result.status === EStatus.SUCCESS) {
                yield put(actSaveMySurveySucc('서베이 저장 성공'));
            } else {
                yield put(actSaveMySurveyFail(result?.detail as string));
            }
        } else {
            yield put(actSaveMySurveyFail('[POST] /api/survey 실패 1' as string));
        }
    } catch (error) {
        console.log(error);
        yield put(actSaveMySurveyFail('[POST] /api/survey 실패 2'));
    }
}

function* watchSurvey() {
    yield takeEvery(IActSurveyType.GET_MY_SURVEY_REQUEST, requestGetMySurvey);
    yield takeEvery(IActSurveyType.SAVE_MY_SURVEY_REQUEST, requestSaveMySurvey);
}

function* sagaSurvey(): Generator {
    yield all([fork(watchSurvey)]);
}
  
export default sagaSurvey;