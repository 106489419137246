import axios, { AxiosResponse } from "axios";
import { all, call, delay, fork, put, takeEvery } from "redux-saga/effects";
import { actPaymentCuidFail, actPaymentCuidSucc, actPaymentMonthlyFail, actPaymentMonthlySucc, actCouponSucc,actCouponFail, } from "../actions/actPayment";
import { IApiPaymentResult, ICouponData, IPaymentCuidData,ICouponResult } from "../interfaces/iPayment/iPayment.interface";
import { IActCouponRequest, IActPaymentCuidRequest, IActPaymentMonthlyRequest, IActPaymentType } from "../interfaces/iPayment/iPaymentAction.interface";
import { API_URL } from "../src/config/config.constant";



// customer_uid 불러오기
function* requestPaymentCuid(action: IActPaymentCuidRequest) {
    // yield delay(1000);
    try {
        const token = action?.token;
        const headers = { headers: { Authorization: 'JWT ' + token } };
        const params = {};

        const apiUrl = API_URL + '/api/payment/cuid/';
        const axioResult: AxiosResponse<IApiPaymentResult> = yield call(
            axios.get,
            apiUrl,
            headers,
        );
        const result = axioResult?.data;
        if (axioResult?.status === 200) {
            // console.log('======')
            // console.log(result)
            // console.log('======')
            const cuidResult = {customer_uid: result?.data?.customer_uid} as IPaymentCuidData;

            yield put(actPaymentCuidSucc('get customer_uid', cuidResult));
        } else {
            yield put(actPaymentCuidFail(result?.detail as string));
        }
    } catch (error) {
        console.log(error);
        yield put(actPaymentCuidFail('customer_uid 실패 2'));
    }
}

// 아임포트 결제 후 정기결제 요청
function* requestPaymentMonthly(action: IActPaymentMonthlyRequest) {
    // yield delay(1000);
    try {
        const token = action?.token;
        const headers = { headers: { Authorization: 'JWT ' + token } };
        const params = action?.body;

        const apiUrl = API_URL + '/api/payment/monthly/';
        const axioResult: AxiosResponse<IApiPaymentResult> = yield call(
            axios.post,
            apiUrl,
            params,
            headers,
        );
        const result = axioResult?.data;
        if (axioResult?.status === 200) {
            // console.log('======')
            // console.log(result)
            // console.log('======')
            const d = result?.data;

            yield put(actPaymentMonthlySucc('정기결제 요청 완료', d));
        } else {
            yield put(actPaymentMonthlyFail(result?.detail as string));
        }
    } catch (error) {
        console.log(error);
        yield put(actPaymentMonthlyFail('정기결제 요청 실패'));
    }
}

function* requestCoupon(action: IActCouponRequest) {
    try {
        const token = action?.token;
        const headers = { headers: { Authorization: 'JWT ' + token } };
        const params = {};

        const apiUrl = API_URL + '/api/coupon/use/'+'?code='+action?.body.code;
        const result: AxiosResponse<ICouponResult> = yield call(
            axios.get,
            apiUrl,
            headers,
        );

        const res = result?.data

        if (result?.status === 200  && result?.data?.status=='success') {
            // 로그인 회원 정보
            // const userInfo = result?.data;
            yield put(actCouponSucc('쿠폰 등록 성공', result?.data));
        } else {
            yield put(actCouponFail(res?.detail as string));
        }
    } catch (error) {
        console.log(error);
        yield put(actCouponFail('쿠폰 등록 실패 2'));
    }
}

function* watchPayment() {
    yield takeEvery(IActPaymentType.CUID_REQUEST, requestPaymentCuid);
    yield takeEvery(IActPaymentType.PAYMENT_MONTHLY_REQUEST, requestPaymentMonthly);
    yield takeEvery(IActPaymentType.COUPON_REQUEST, requestCoupon);
}

function* sagaPayment(): Generator {
    yield all([fork(watchPayment)]);
}
  
export default sagaPayment;
