import { HYDRATE } from 'next-redux-wrapper';
import { DefaultState } from '../interfaces/ifDefault/ifDefault.interfaces';
import { ActionsDefault, actionTypesDefault } from '../interfaces/ifDefault/ifDefaultAct.interfaces';

export const initialState: DefaultState = {
    isOpenBackdrop: false,
};

interface HydratePayload {
    rdcDefault: DefaultState;
}

const rdcDefault = (
    state = initialState,
    // action: AnyAction,
    action: ActionsDefault | { type: typeof HYDRATE; payload: HydratePayload },
): DefaultState => {
    switch (action.type) {
        case HYDRATE:
        return { ...state, ...action.payload.rdcDefault };
        case actionTypesDefault.SET_BACKDROP:
        return {
            ...state,
            ...{ isOpenBackdrop: action.isOpenBackdrop },
        };
        default:
        return state;
    }
};

export default rdcDefault;
