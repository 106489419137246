import { IGerMySurveyResult } from "./iSurvey.interface";

export enum IActSurveyType {
    GET_MY_SURVEY_INIT = 'GET_MY_SURVEY_INIT', // 나의 서베이 API 초기화
    GET_MY_SURVEY_MSG_INIT = 'GET_MY_SURVEY_MSG_INIT', // 나의 서베이 API 메시지 초기화
    GET_MY_SURVEY_REQUEST = 'GET_MY_SURVEY_REQUEST', // 나의 서베이 API 요청
    GET_MY_SURVEY_SUCC_MSG = 'GET_MY_SURVEY_SUCC_MSG', // 나의 서베이 API 성공
    GET_MY_SURVEY_FAIL_MSG = 'GET_MY_SURVEY_FAIL_MSG', // 나의 서베이 API 실패

    SAVE_MY_SURVEY_MSG_INIT = 'SAVE_MY_SURVEY_MSG_INIT', // 나의 서베이 저장 API 메시지 초기화
    SAVE_MY_SURVEY_REQUEST = 'SAVE_MY_SURVEY_REQUEST', // 나의 서베이 저장 API 요청
    SAVE_MY_SURVEY_SUCC_MSG = 'SAVE_MY_SURVEY_SUCC_MSG', // 나의 서베이 저장 API 성공
    SAVE_MY_SURVEY_FAIL_MSG = 'SAVE_MY_SURVEY_FAIL_MSG', // 나의 서베이 저장 API 실패
}



export type IActSurvey = IActGetMySurveyInit
                        | IActGetMySurveyMsgInit
                        | IActGetMySurveyRequest
                        | IActGetMySurveySucc
                        | IActGetMySurveyFail
                        | IActSaveMySurveyMsgInit
                        | IActSaveMySurveyRequest
                        | IActSaveMySurveySucc
                        | IActSaveMySurveyFail;

// [GET] {{url}}/api/survey 초기화
export interface IActGetMySurveyInit {
    type: IActSurveyType.GET_MY_SURVEY_INIT;
}
// [GET] {{url}}/api/survey msg 초기화
export interface IActGetMySurveyMsgInit {
    type: IActSurveyType.GET_MY_SURVEY_MSG_INIT;
}
// [GET] {{url}}/api/survey request
export interface IActGetMySurveyRequest {
    type: IActSurveyType.GET_MY_SURVEY_REQUEST;
    token: string;
}
// [GET] {{url}}/api/survey succ
export interface IActGetMySurveySucc {
    type: IActSurveyType.GET_MY_SURVEY_SUCC_MSG;
    getMySurveySuccMsg: string;
    getMySurveyData: IGerMySurveyResult;
}
// [GET] {{url}}/api/survey fail
export interface IActGetMySurveyFail {
    type: IActSurveyType.GET_MY_SURVEY_FAIL_MSG;
    getMySurveyFailMsg: string;
}


// [POST] {{url}}/api/survey msg 초기화
export interface IActSaveMySurveyMsgInit {
    type: IActSurveyType.SAVE_MY_SURVEY_MSG_INIT;
}
// [POST] {{url}}/api/survey request
export interface IActSaveMySurveyRequest {
    type: IActSurveyType.SAVE_MY_SURVEY_REQUEST;
    token: string;
    body: any;
}
// [POST] {{url}}/api/survey succ
export interface IActSaveMySurveySucc {
    type: IActSurveyType.SAVE_MY_SURVEY_SUCC_MSG;
    saveMySurveySuccMsg: string;
}
// [POST] {{url}}/api/survey fail
export interface IActSaveMySurveyFail {
    type: IActSurveyType.SAVE_MY_SURVEY_FAIL_MSG;
    saveMySurveyFailMsg: string;
}
