// import { IsOpenBackdrop } from './ifDefault.interfaces';

export enum actionTypesDefault {
    SET_BACKDROP = 'SET_BACKDROP',
}

export type ActionsDefault = IfActSetBackdrop;

export interface IfActSetBackdrop {
    type: actionTypesDefault.SET_BACKDROP;
    isOpenBackdrop: boolean;
}
