import { HYDRATE } from 'next-redux-wrapper';
import { IUserStateInterface } from '../interfaces/iUser/iUser.interface';
import { IActUser, IActUserType } from '../interfaces/iUser/iUserAction.interface';
import { JWT_COOKIE_KEY } from '../src/constant/key.constant';
import { removeCookie } from '../src/lib/cookie';

export const initialUserState: IUserStateInterface = {
    loginRequest: false,
    loginSuccMsg: null,
    loginFailMsg: null,

    kakaotalkAccessToken: '',

    loginUserData: null,

    userInfoRequest: false,
    userInfoSuccMsg: '',
    userInfoFailMsg: '',
};

interface HydratePayload {
    rdcUser: IUserStateInterface;
}

const rdcUser = (
    state = initialUserState,
    // action: AnyAction,
    action: IActUser | { type: typeof HYDRATE; payload: HydratePayload },
): IUserStateInterface => {
    switch (action.type) {
        case HYDRATE:
            return { ...state, ...action.payload.rdcUser };
        case IActUserType.REQUEST_USER_LOGIN:
            return {
                ...state,
                ...{
                    loginRequest: true,
                    kakaotalkAccessToken: action.kakaotalkAccessToken,
                    loginSuccMsg: null,
                    loginFailMsg: null,
                    loginUserData: null,
                },
            };
        case IActUserType.USER_LOGIN_SUCC:
            return {
                ...state,
                ...{
                    loginRequest: false,
                    kakaotalkAccessToken: null,
                    loginSuccMsg: action.loginSuccMsg,
                    loginFailMsg: null,
                    loginUserData: action.loginUserData,
                },
            };
        case IActUserType.USER_LOGIN_FAIL:
            return {
                ...state,
                ...{
                    loginRequest: false,
                    kakaotalkAccessToken: null,
                    loginSuccMsg: null,
                    loginFailMsg: action.loginFailMsg,
                    loginUserData: null,
                },
            };
        case IActUserType.USER_LOGOUT:
            // token 삭제
            removeCookie(JWT_COOKIE_KEY);

            return {
                ...state,
                ...{
                    loginRequest: false,
                    kakaotalkAccessToken: null,
                    loginSuccMsg: null,
                    loginFailMsg: null,
                    loginUserData: null,
                },
            };
        case IActUserType.REQUEST_USER_INFO:
            return {
                ...state,
                ...{
                    token: action?.token,
                },
            };
        case IActUserType.REQUEST_USER_INFO_SUCC:
            return {
                ...state,
                ...{
                    loginUserData: action?.loginUserData,
                    userInfoRequest: false,
                    userInfoSuccMsg: action?.userInfoSuccMsg,
                    userInfoFailMsg: '',
                },
            };
        case IActUserType.REQUEST_USER_INFO_FAIL:
            return {
                ...state,
                ...{
                    loginUserData: null,
                    userInfoRequest: false,
                    userInfoSuccMsg: '',
                    userInfoFailMsg: action?.userInfoFailMsg,
                },
            };
        default:
            return state;
    }
};

export default rdcUser;
